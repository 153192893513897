import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import AboutUs from "./about-us/page";
import AllTest from "./all-test/page";
import LandingPage from "./LandingPage.js";
import CheckOut from "./check-out/page";
import TestCategory from "./TestCategories.js";
import DoctorProfile from "./DoctorListing.js";
import DoctorDetails from "./DoctorDetails.js";
import EnquirySubmitted from "./enquiry-submitted/page";
import MediaVideos from "./media/videos/page";
import Organs from "./organs/[slug]/page";
import Packages from "./Packages/Index.js";
import DetailPage from "./DetailPage/Index.js";
import Parameter from "./parameter/[slug]/page";
import Pharmacy from "./pharmacy/page";
import PrivacyPolicy from "./privacy-policy/page";
import RefundCancellation from "./refund-cancellation/page";
import Search from "./search/[slug]/page";
import TermsConditions from "./terms-conditions/page";
import NotFound from "./NotFound.js";
import TestDetail from "./test-detail/[slug]/page";
import Alert from "./components/Alert";
import Loader from "./components/Loader";
import SpeedDial from "./components/SpeedDial";
import Cart from "./components/Cart.js";
import { BreadCrums } from "./components/BreadCrums";
import UploadForm from "./components/UploadForm";

import "./index.css";
import "./page.module.css";
import "./App.css";
import "./font/stylesheet.css";
import "./responsive.css";
import "./globals.css";
import "./responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "./components/ScrollToTop.js";
import SmoothScroll from "./components/SmoothScroller/index.js";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <SmoothScroll />
      {/* <Loader /> */}
      <Header />
      <BreadCrums />
      <Cart />
      <Alert />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/all-test" element={<AllTest />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/check-out" element={<CheckOut />} />
        <Route path="/doctors" element={<DoctorProfile />} />
        <Route path="/doctors/:slug" element={<DoctorDetails />} />
        <Route path="/enquiry-submitted" element={<EnquirySubmitted />} />
        <Route path="/media/videos" element={<MediaVideos />} />
        <Route path="/all-test/:slug" element={<DetailPage />} />
        <Route path="/packages/:slug" element={<DetailPage />} />
        <Route path="/parameter/:slug" element={<DetailPage />} />
        <Route path="/test-detail/:slug" element={<DetailPage />} />
        <Route path="/condition/:slug" element={<TestCategory />} />
        <Route path="/organs/:slug" element={<TestCategory />} />
        <Route path="/search/:slug" element={<Search />} />
        <Route path="/campaign/:slug" element={<LandingPage />} />
        <Route path="/pharmacy" element={<Pharmacy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-cancellation" element={<RefundCancellation />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <SpeedDial />
      <Footer />
      <UploadForm />
    </Router>
  );
}

export default App;
