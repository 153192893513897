import React, { useEffect, useState } from "react";
import axios from "axios"; // You can also use the fetch API
import { Link } from "react-router-dom";
import "./index.css";
import { BASE_URL } from "./context/UseApi";
import { Helmet } from "react-helmet";

const DoctorListing = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${BASE_URL}/doctor_data.php`)
      .then((response) => {
        // Assuming the API returns data in the format { doctors: [...] }
        setDoctors(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the doctor data:", error);
        setError("Failed to load doctor data.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="_loader_cnt col-12 d-flex justify-content-center">
        <div className="_loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="h-screen col-12 flex-center ">{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Meet Our Doctors | Assure Pathlabs</title>
        <meta
          name="description"
          content="Discover our team of expert doctors at Assure Pathlabs. Meet Dr. Sanjay Wadhwa and Dr. Lovely Razdan, our co-founders dedicated to providing exceptional healthcare services."
        />
        <meta
          name="keywords"
          content="Assure Pathlabs, Dr. Sanjay Wadhwa, Dr. Lovely Razdan, healthcare, expert doctors, medical services,pathlogy doctors, diagnostic services, medical tests, health checkup, preventive health checkup"
        />
        <meta
          property="og:title"
          content="Meet Our Doctors | Assure Pathlabs"
        />
        <meta
          property="og:description"
          content="Discover our team of expert doctors at Assure Pathlabs. Meet Dr. Sanjay Wadhwa and Dr. Lovely Razdan, our co-founders dedicated to providing exceptional healthcare services."
        />
        <meta
          property="og:image"
          content="https://triverseadvertising.com/assure_website//all_images/1046848803doctorsanjay.jpg"
        />
        <meta property="og:image:alt" content="Dr. Sanjay Wadhwa" />
        <meta
          property="og:url"
          content="https://www.assurepathlabs.com/doctors"
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Meet Our Doctors | Assure Pathlabs"
        />
        <meta
          name="twitter:description"
          content="Discover our team of expert doctors at Assure Pathlabs. Meet Dr. Sanjay Wadhwa and Dr. Lovely Razdan, our co-founders dedicated to providing exceptional healthcare services."
        />
        <meta
          name="twitter:image"
          content="https://triverseadvertising.com/assure_website//all_images/1046848803doctorsanjay.jpg"
        />
        <meta name="twitter:image:alt" content="Dr. Sanjay Wadhwa" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.assurepathlabs.com/doctors" />
      </Helmet>
      <section className="position-relative">
        <div className="container">
          <div className="row">
            <div className="title col-12 float-start text-center">
              <h2>Our Doctors</h2>
            </div>
            <div className="leaderships">
              {doctors.map((doctor, index) => (
                <div className="leadership2" key={index}>
                  <div className="leadershipimg">
                    <img
                      src={doctor.image.src}
                      alt={doctor.image.alt}
                      title={doctor.image.title}
                      width="350"
                      height="350"
                    />
                  </div>
                  <div className="leadershipcont">
                    <h2>{doctor.doctor_name}</h2>
                    <ul>
                      <li>{doctor.position}</li>
                      <li>{doctor.organization}</li>
                    </ul>
                    <div className="col-12 d-flex justify-content-start">
                      <Link
                        to={`/doctors/${doctor.slug}`}
                        className="textbtn text-black mt-2"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorListing;
