import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "../context/AlerterContext";
import styles from "../page.module.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css"; // Import Typeahead CSS for default styling
import Call from "./svg-components/Call";
import WhatsApp from "./svg-components/WhatsApp";

// Updated validation schema with future date validation
const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Name should have a minimum length of 3 characters")
    .required("Name is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid Phone Number format (should be 10 digits)")
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  collectiondatetime: Yup.date()
    .min(new Date(), "Collection date and time must be in the future")
    .required("Collection date and time is required"),
  selectedPackage: Yup.object()
    .shape({
      id: Yup.string().required(),
      Test_Name: Yup.string().required(),
      Test_Amount: Yup.number().required(),
    })
    .nullable()
    .required("Please select a package"),
});

const HomeCollection = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [isDateInputActive, setDateInputActive] = useState(false);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  // Fetch the data from the provided API
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          "https://triverseadvertising.com/assure_website/api/algos/fetch_details.php?category=testpack"
        );
        setPackages(response.data.test_data);

        // Filter the packages based on Test_Name after receiving the response
        const filteredData = response.data.test_data.map((item) => ({
          id: item.id,
          Test_Name: item.Test_Name,
          Test_Amount: item.Test_Amount,
        }));
        setFilteredPackages(filteredData);
      } catch (error) {
        console.error("Error fetching packages", error);
        showAlert(
          "Failure",
          "Error fetching packages, please try again.",
          "error"
        );
      }
    };
    fetchPackages();
  }, []); // Re-run effect when data is fetched

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      collectiondatetime: "",
      selectedPackage: null,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const payload = {
          ...values,
          selectedPackage: values.selectedPackage?.Test_Name,
        };

        const response = await axios.post(
          "https://triverseadvertising.com/assure_website/api/algos/booking_submit_api.php",
          payload
        );
        if (response.data.success) {
          const params = new URLSearchParams({
            name: values.name,
            phone: values.phone,
            email: values.email,
            collectiondatetime: values.collectiondatetime,
            packageName: values.selectedPackage?.Test_Name,
            packageAmount: values.selectedPackage?.Test_Amount,
          }).toString();

          navigate(`/enquiry-submitted?${params}`);
          showAlert("Success", "Our team will contact you soon", "success");
          console.log(response.data);
          formik.resetForm();
        } else {
          showAlert(
            "Failure",
            "Error in submitting inquiry, contact us on call or email",
            "error"
          );
        }
      } catch (error) {
        console.error("Error during submission:", error);
        showAlert(
          "Failure",
          "Error in submitting inquiry, please try again later",
          "error"
        );
      }
      setSubmitting(false);
    },
  });

  // Custom SearchBar with Typeahead
  const handleSearchChange = (selected) => {
    if (selected && selected.length > 0) {
      formik.setFieldValue("selectedPackage", selected[0]);
    } else {
      // Clear the selectedPackage when the cross button is clicked
      formik.setFieldValue("selectedPackage", null);
    }
  };

  return (
    <>
      <div className="enquireform col-11 mx-auto">
        <div className={styles.equireheading}>
          <article className="col-12 mx-auto">
            <span>BOOK HOME COLLECTION</span>
          </article>
        </div>

        <form onSubmit={formik.handleSubmit} className="collectionForm">
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name *"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            // helperText={formik.touched.name && formik.errors.name}
            className="input-field"
          />

          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Phone Number *"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            // helperText={formik.touched.phone && formik.errors.phone}
            className="input-field"
          />

          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email Address *"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            // helperText={formik.touched.email && formik.errors.email}
            className="input-field"
          />
          {/* Package Search with Typeahead */}
          <div className="input-field_enquire">
            <Typeahead
              id="package-search"
              onChange={handleSearchChange}
              options={filteredPackages} // Use the filtered data
              labelKey="Test_Name" // Display Test_Name
              selected={
                formik.values.selectedPackage
                  ? [formik.values.selectedPackage]
                  : []
              }
              renderMenuItemChildren={(option) => (
                <div className="d-flex flex-column">
                  <span>{option.Test_Name}</span>
                  <span style={{ fontSize: "12px", color: "gray" }}>
                    ₹{option.Test_Amount}
                  </span>
                </div>
              )}
              placeholder="Choose for a Test/Package *"
              minLength={1}
              // clearButton={true}
              highlightOnlyResult={true}
              className={
                formik.touched.selectedPackage && formik.errors.selectedPackage
                  ? "input-error"
                  : ""
              }
            />
          </div>
          <TextField
            fullWidth
            id="collectiondatetime"
            name="collectiondatetime"
            label="Sample Collection Date & Time *"
            type={isDateInputActive ? "datetime-local" : "text"} // Toggle type
            value={formik.values.collectiondatetime}
            // onBlur={() => setDateInputActive(false)} // Change back to text on blur
            onFocus={() => setDateInputActive(true)} // Change to datetime-local on focus
            onChange={formik.handleChange}
            error={
              formik.touched.collectiondatetime &&
              Boolean(formik.errors.collectiondatetime)
            }
            InputLabelProps={{ shrink: isDateInputActive }}
            className="input-field Time_date"
          />

          <button
            type="submit"
            className="col-12 mt-3 col-md-12 mx-md-auto button button--aylen button--round-l button--text-thick"
            disabled={formik.isSubmitting}
            onClick={async () => {
              const formErrors = await formik.validateForm(); // Use formik.validateForm()
              if (Object.keys(formErrors).length > 2) {
                showAlert(
                  "Failure",
                  "Please complete all required fields.",
                  "error"
                );
              } else if (Object.keys(formErrors).length > 0) {
                const errorMessages = Object.values(formErrors).join(". ");
                showAlert("Failure", errorMessages, "error");
              }
            }}
          >
            {formik.isSubmitting ? "Submitting..." : "SUBMIT"}
          </button>
        </form>
      </div>
      <hr className="m-xxl-4 m-2  d-xl-inline-block d-none w-100" />
      <div className="col-12 gap-5 float-start mt-2xl-3 flex-center d-none d-xl-flex  justify-content-center">
        <div className=" grid-center text-center text-black gap-1 whatsapp">
          <Link
            target="_blank"
            to="https://api.whatsapp.com/send/?phone=%2B919716664040&text=Hello%20Assure%20Team%2C%0A%0AI%E2%80%99d%20like%20to%20connect%20with%20a%20Health%20Advisor.%20Could%20you%20assist%20me%3F%20Also%2C%20I%E2%80%99m%20interested%20in%20learning%20more%20about%20your%20tests%20and%20packages.%0A%0AThank%20you%21&type=phone_number&app_absent=0
"
          >
            <WhatsApp />
          </Link>
        </div>
        <div className=" grid-center text-center text-black gap-1 call">
          <Link target="_blank" to="tel:01814667555">
            <Call />
          </Link>
        </div>
      </div>
    </>
  );
};

export default HomeCollection;
