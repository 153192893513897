import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M128.924 321.521h288.813a41.422 41.422 0 0 0 40.343-31.69l42.04-172.482a12.002 12.002 0 0 0-11.659-14.842h-91.164c-12.942-43.548-53.329-75.403-101.023-75.403s-88.081 31.854-101.023 75.403H101.59L84.906 32.441a12.001 12.001 0 0 0-11.674-9.22H23.538c-6.627 0-12 5.373-12 12s5.373 12 12 12h40.216l59.684 250.639c-22.777 2.722-40.498 22.147-40.498 45.645 0 25.355 20.628 45.984 45.983 45.984h3.692c-18.303 8-31.13 26.269-31.13 47.487 0 28.564 23.239 51.804 51.804 51.804s51.805-23.239 51.805-51.804c0-21.218-12.827-39.488-31.13-47.487h226.338c-18.303 8-31.13 26.269-31.13 47.487 0 28.564 23.239 51.804 51.804 51.804s51.805-23.239 51.805-51.804c0-21.218-12.827-39.488-31.13-47.487h16.367c6.627 0 12-5.373 12-12s-5.373-12-12-12H128.924c-12.122 0-21.983-9.862-21.983-21.984s9.862-21.984 21.983-21.984zM296.275 51.104c44.868 0 81.371 36.503 81.371 81.372 0 44.868-36.503 81.371-81.371 81.371s-81.372-36.503-81.372-81.371c0-44.869 36.504-81.372 81.372-81.372zm-105.199 75.403a106.371 106.371 0 0 0-.173 5.969c0 58.102 47.27 105.371 105.372 105.371s105.371-47.269 105.371-105.371c0-2.003-.062-3.993-.173-5.969h71.712l-38.422 157.64c-1.95 8-8.792 13.374-17.025 13.374H148.029l-40.723-171.014zM153.29 464.779c-15.331 0-27.804-12.473-27.804-27.804s12.473-27.804 27.804-27.804c15.332 0 27.805 12.473 27.805 27.804s-12.473 27.804-27.805 27.804zm267.686 0c-15.331 0-27.804-12.473-27.804-27.804s12.473-27.804 27.804-27.804c15.332 0 27.805 12.473 27.805 27.804s-12.473 27.804-27.805 27.804zM240.31 132.476c0-6.627 5.373-12 12-12h31.965V88.51c0-6.627 5.373-12 12-12s12 5.373 12 12v31.965h31.965c6.627 0 12 5.373 12 12s-5.373 12-12 12h-31.965v31.965c0 6.627-5.373 12-12 12s-12-5.373-12-12v-31.965H252.31c-6.628.001-12-5.372-12-11.999z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
