import React, { useEffect, useState } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Dots } from "./components/svg-components/Dots";
import { Line } from "./components/svg-components/Line";
import { AssureSlide } from "./components/AssureSlide";
import { ProductSlider } from "./components/ProductSlider";
import { Seasonalpack } from "./components/Seasonalpack";
import { Organslider } from "./components/Organslider";
import { TestByCondition } from "./components/TestByCondition";
import { Testimonials } from "./components/Testimonials";
import { Videos } from "./components/Videos";
import { Faq } from "./components/Faq";
import WhyUs from "./components/WhyUs";
import AboutData from "./components/AboutData";
import BgGradientLayer from "./images/gradient-layer.webp";
import { BASE_URL } from "./context/UseApi";
import axios from "axios"; // Make sure to import axios
import { useLocation } from "react-router-dom";
import HomeCollection from "./components/Homecollection";

export default function Page() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  console.log("is home page", isHomePage);

  useEffect(() => {
    Aos.init();

    setIsLoading(true);
    axios
      .get(`${BASE_URL}/home.php`)
      .then((response) => {
        console.log("this is the data ", response.data);
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  const aboutData = data.aboutus?.[0];
  if (isLoading) {
    return (
      <>
        <div className="_loader_cnt col-12 d-flex justify-content-center">
          <div className="_loader"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {isHomePage && (
        <Helmet>
          <title>
            Best Pathology Lab in Jalandhar | Dr Pathlabs &amp; Diagnostic Labs
            in Jalandhar | Assure Pathlabs
          </title>
          <meta
            name="description"
            content="Assure Pathlabs - Best Pathology Lab in Jalandhar | Get Best Health Check-Up Packages in Jalandhar from Assure | Best diagnostic Labs in Jalandhar"
          />
          <link rel="canonical" href="https://www.assurepathlabs.com/" />
          <meta name="keywords" content="Assure Pathlabs" />
          <meta
            property="og:title"
            content="Best Pathology Lab in Jalandhar | Dr Pathlabs &amp; Diagnostic Labs in Jalandhar | Assure Pathlabs"
          />
          <meta
            property="og:description"
            content="Assure Pathlabs - Best Pathology Lab in Jalandhar | Get Best Health Check-Up Packages in Jalandhar from Assure | Best diagnostic Labs in Jalandhar"
          />
          <meta
            property="og:image"
            content="https://www.assurepathlabs.com/images/assure-logo.png"
          />
          <meta property="og:url" content="https://www.assurepathlabs.com/" />
          <meta
            name="twitter:title"
            content="Best Pathology Lab in Jalandhar | Dr Pathlabs &amp; Diagnostic Labs in Jalandhar | Assure Pathlabs"
          />
          <meta
            name="twitter:description"
            content="Assure Pathlabs - Best Pathology Lab in Jalandhar | Get Best Health Check-Up Packages in Jalandhar from Assure | Best diagnostic Labs in Jalandhar"
          />
          <meta
            name="twitter:url"
            content="https://www.assurepathlabs.com/images/assure-logo.png1235465"
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>
      )}
      <main className="d-flex flex-wrap float-start col-12 mt-0">
        <section className="position-relative _home__banner">
          <div className="gradient-layer"></div>
          <div className="container">
            <div className="__Banner">
              <div className="row ">
                <div
                  className="col-xl-9 col-lg-8 col-md-12 pull-md-right mr-md-auto col-sm-12 mb-0 pr-0  col-12"
                  style={{ paddingRight: 0 }}
                >
                  <AssureSlide
                    Data={data.banners}
                    className="no-buttons colornavigations col-12 float-start"
                  />
                </div>
                <div
                  className="col-xl-3 col-lg-4 col-sm-12 float-end  justify-content-center col-12 d-flex  align-items-center bg-light-gray"
                  id="Home-Collection-data"
                >
                  <HomeCollection />
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10" />
          <Line className="svgwidthline position-absolute opacity-10" />
        </section>
        <section className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div
                  className="col-md-12 col-12"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <div className="title col-12 float-start text-center">
                    <h2 className="grid-center ">Popular Seasonal Packages</h2>
                  </div>
                  <Seasonalpack className="arrows productslider minusbottom seasonalpack" />
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10 end-0 left-inherit" />
          <Line className="svgwidthline position-absolute opacity-10 bottom-0 start-0" />
        </section>
        <section className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div className="col-md-12 col-12">
                  <div
                    className="title col-12 float-start text-center"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-once="true"
                    data-aos-easing="ease-in"
                  >
                    <h2>Popular Wellness Packages</h2>
                  </div>
                  <ProductSlider className="colornavigations col-12 float-start arrows productslider minusbottom" />
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10" />
          <Line className="svgwidthline position-absolute opacity-10 top-20 end-0" />
        </section>
        <section id="organs" className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div
                  className="col-md-12 col-12"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <div className="title col-12 float-start text-center mb-3">
                    <h2 className="grid-center text-white">TEST BY ORGAN</h2>
                  </div>
                  <Organslider className="navigationwhite hovershadow" />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gradient-image position-absolute">
            <img
              src={BgGradientLayer}
              alt="gradient file"
              width={"1920px"}
              height={"1752px"}
            />
          </div>
        </section>
        <section className="position-relative mt-3">
          <div className="container">
            <TestByCondition />
          </div>
          <Line className="svgwidthline position-absolute opacity-10 bottom-0 start-0" />
        </section>
        <section className="position-relative py-lg-3 py-md-0 mt-2">
          <div className="container">
            <div className="web-container">
              <div className="col-12 float-left">
                <div
                  className="title col-12 mx-auto abt_title float-start text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <h1 className="text-uppercase">
                    Assure Pathlabs - Best Pathology Labs In Jalandhar
                  </h1>
                </div>
                <div
                  className="abt_scn__"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <div className="about_us_scn ">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <div className="title float-start">
                          <h2 className="text-uppercase">
                            Assure Pathlabs - Best Pathology Labs In Jalandhar
                          </h2>
                        </div>
                        <div className="about_us_cnt text-center">
                          {aboutData?.hdesc1 && <p>{aboutData.hdesc1}</p>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div
                          className="about_us_image"
                          data-aos="fade"
                          data-aos-delay={100}
                          data-aos-duration={600}
                          data-aos-once="true"
                          data-aos-easing="ease"
                        >
                          {aboutData?.home_image && (
                            <img
                              src={aboutData.home_image}
                              width={850}
                              height={700}
                              alt="About Us"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <AboutData maxCharCount={0}>
                  {aboutData?.hdesc2 && (
                    <div
                      className="contentbox assure_about"
                      dangerouslySetInnerHTML={{ __html: aboutData.hdesc2 }}
                    />
                  )}
                </AboutData>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10 end-0 left-inherit" />
        </section>
        <section className="position-relative  ">
          <WhyUs Title="WHY ASSURE PATHLABS?" />

          <div className="testimonial">
            <div className=" position-relative z-index-2">
              <div className="">
                <div className="row">
                  <div
                    className="title col-12 float-start text-center"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-once="true"
                    data-aos-easing="ease-in"
                  >
                    <h2 className="text-white">TESTIMONIALS</h2>
                  </div>
                  <div className=" col-12 m-auto float-start">
                    <Testimonials className="minusbottom navigationwhite" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10 start-0 bottom-0 top-inherit" />
          <Line className="svgwidthline position-absolute opacity-10 bottom-0 end-0" />
        </section>
        <section id="faq" className="faq">
          <div className="container position-relative z-index-2">
            <div className="web-container">
              <div
                className="row"
                data-aos="fade-up"
                data-aos-duration={500}
                data-aos-once="true"
                data-aos-easing="ease-in"
              >
                <Faq className="minusbottom " slug={"home"} />
              </div>
            </div>
          </div>
        </section>
        <section id="vedio_review" className="vedio_review">
          <div className="container position-relative z-index-2">
            <div className="web-containers">
              <div className="row">
                <div
                  className="title col-12 float-start text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <h2 className="">OUR VIDEOS</h2>
                </div>
                <div className="col-lg-10 col-12 m-auto float-start d-flex flex-column">
                  <Videos />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
