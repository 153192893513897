"use client";
import React from "react";
import { AssureSafety } from "./svg-components/AssureSafety";
import { FreeHomeCollection } from "./svg-components/FreeHomeCollection";
import SameDayreport from "./svg-components/SameDayreport";
import AffordablePricing from "./svg-components/AffordablePricing";
import PlayStore from "./svg-components/PlayStore";
import Nabh from "./svg-components/Nabh";
import { FiveHundredplus } from "./svg-components/FiveHundredplus";
import { Dots } from "./svg-components/Dots";
import { Line } from "./svg-components/Line";
import data from "../Data/certification.json";
import { Link } from "react-router-dom";



export const Certifactions = () => {
  const { title, icons } = data;

  return (
    <section className="position-relative">
      <div className="container">
        <div className="web-container">
          <div className="row">
            <div
              className="title col-12 float-start text-center "
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-once="true"
              data-aos-easing="ease-in"
            >
              <h2>Certifications</h2>
            </div>
            <div className="col-lg-8 m-auto col-12 float-start chooseassure certificates pt-3">
              <div
                className="row justify-content-center"
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration={500}
                data-aos-once="true"
                data-aos-easing="linear"
              >
                {icons.map(({ id, icon, pdf, text }) => (
                  <Link
                    to={pdf}
                    target="_blank"

                    key={id}
                    className="col-lg-3 col-sm-4 col-6 chooseUsIcon"
                  >
                    <div className="circleicons float-start col-12 text-center grid-center" title={text}>
                      <img src={icon} alt={text} />

                      {/* <h3 dangerouslySetInnerHTML={{ __html: text }}></h3> */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dots className="hsection position-absolute svgwidth opacity-10 start-0 bottom-0 top-inherit" />
      {/* <Line className="svgwidthline position-absolute opacity-10 bottom-0 end-0" /> */}
    </section>
  );
};
