import React from 'react'

export const Allergy = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 500 500"
    {...props}
  >
    <defs>
      <style>{".cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="M267.19 450.78h-32.81c-6.56-.93-13.19-1.49-19.66-2.86-78.11-16.56-129.86-62.89-155.62-138.34-4.6-13.47-6.57-27.54-8.32-41.61v-34.38c.93-6.3 1.52-12.67 2.86-18.88 17.31-80.48 65.45-132.8 143.97-157.22 11.71-3.64 23.87-5.1 35.98-6.72h34.38c6.42.95 12.92 1.55 19.26 2.9C365 70.29 416.58 116.5 442.35 191.59c4.74 13.83 6.83 28.28 8.44 42.77v32.81c-.94 6.56-1.48 13.2-2.87 19.65-17.33 80.44-65.4 132.83-143.96 157.21-11.96 3.71-24.37 5.26-36.77 6.73ZM62.81 250.05c.42 105.1 83.94 189.07 188.9 188.51 104.45-.56 186.58-83.87 186.84-187.25.27-105.41-84.23-189-188.91-188.31-103.96.68-185.62 83.56-186.84 187.05Z"
      className="cls-1"
    />
    <path
      d="M242.94 425.25c-33.85-.63-70.69-13.57-102.84-39.57-6.39-5.17-6.56-8.1-.75-13.91 75.57-75.58 151.15-151.16 226.73-226.73 2.21-2.21 4.4-4.44 6.63-6.62 4.62-4.51 7.97-4.36 12.08.6 14.28 17.24 25.06 36.42 31.89 57.76 30 93.59-21.57 192.27-115.55 221.15-15.31 4.71-33.61 7.35-58.2 7.33Zm169.49-175.11c-.26-5.04-.15-10.88-.79-16.73-3.14-28.87-13.34-54.95-30.39-78.4-3.07-4.22-3.1-4.2-6.8-.5-73.35 73.35-146.71 146.7-220.06 220.05-3.58 3.58-3.62 3.64.44 6.55 38.4 27.51 81.05 37.33 127.29 28.33 76.27-14.84 130.23-81.05 130.3-159.31ZM76.42 250.99c-.16-83.08 58.08-155 139.61-171.13 54.27-10.73 103.22 2.19 146.39 36.8 5.17 4.15 5.32 7.6.57 12.35-77.96 77.98-155.93 155.96-233.92 233.92-4.79 4.79-8.16 4.63-12.36-.45-22.42-27.14-35.48-58.24-39.49-93.18-.36-3.1-.64-6.22-.78-9.33-.13-2.99-.03-5.99-.03-8.98Zm12.71.83c.24 4.9.16 10.74.81 16.59 3.19 28.87 13.43 54.93 30.53 78.35 2.93 4 2.96 3.98 6.55.39 73.36-73.36 146.71-146.71 220.06-220.07 3.67-3.67 3.7-3.72-.58-6.79-38.31-27.44-80.86-37.06-126.96-28.22-78.21 14.99-131.19 84.3-130.42 159.76Z"
      className="cls-1"
    />
    <path
      d="M350.88 291.18c-.32-21.39-8.5-39.09-24.94-52.89-1.19-1-2.49-1.94-3.47-3.13-2.1-2.53-2.16-5.3-.06-7.84 2.07-2.51 4.82-3.22 7.67-1.52 2.22 1.32 4.29 2.97 6.18 4.73 1.89 1.76 3.49 2.02 5.32.08.44-.47 1.01-.82 1.55-1.18 3.25-2.13 6.52-1.76 8.71.95 2.11 2.6 2.2 6.16-.66 8.38-3.65 2.84-2.94 5.06-.68 8.37 6.41 9.37 10.25 19.86 12.07 31.03.46 2.82 1.72 3.76 4.39 3.65 2.86-.11 5.73-.12 8.59.02 3.78.19 6.4 2.69 6.55 5.97.14 3.1-2.1 5.85-5.67 6.37-2.94.42-5.96.43-8.95.46-2.45.02-3.78.78-4.06 3.52-1.76 17.45-8.17 32.97-19.64 46.31-1.43 1.66-1.57 3.11-.17 4.83 1.64 2.02 3.3 4.04 4.71 6.22 2.02 3.13 1.33 6.68-1.45 8.66-2.59 1.85-6.06 1.45-8.44-1.15-1.49-1.62-2.87-3.38-4.15-5.17-1.37-1.92-2.71-1.95-4.57-.62-12.13 8.7-25.75 13.44-40.55 14.86-2.34.22-3.12 1.42-3.2 3.55-.12 2.99-.28 5.98-.52 8.96-.3 3.74-2.87 6.25-6.21 6.2-3.36-.05-5.88-2.61-6.04-6.37-.13-2.99-.1-5.99-.02-8.98.07-2.5-.88-3.76-3.49-4.16-11.89-1.83-22.82-6.19-32.69-13.05-2.41-1.67-4.05-1.81-5.77.77-1.3 1.94-2.87 3.7-4.4 5.47-3.27 3.78-6.8 4.4-9.81 1.81-2.94-2.53-2.97-5.89-.03-9.69 1.67-2.16 3.36-4.31 5.15-6.38 1.43-1.65 1.31-2.92-.46-4.19-1.16-.83-2.2-1.83-3.25-2.8-4.04-3.68-4.73-7.02-2.1-10.11 2.54-2.99 6.34-2.81 10.31.52 3.79 3.18 7.55 6.39 11.33 9.58 12.62 10.62 27.25 16.52 43.66 16.49 34.34-.07 59.55-22.24 67.25-51.52 1.47-5.58 2.01-11.26 2.02-17.02Z"
      className="cls-1"
    />
    <path
      d="M292.23 336.19c-19.41.02-35.32-15.79-35.39-35.17-.07-19.22 15.96-35.32 35.26-35.41 19.2-.09 35.39 16.03 35.4 35.27.02 19.36-15.89 35.29-35.27 35.31Zm0-12.83c12.71-.03 22.42-9.76 22.41-22.48-.01-12.55-9.99-22.51-22.53-22.49-12.51.02-22.41 10.02-22.39 22.61.03 12.76 9.72 22.38 22.51 22.36ZM144.93 201.93c-.44 8.27 2.28 15.87 5.11 23.45 5.88 15.79 13.37 30.78 22.34 45.04.62.99 1.29 1.97 1.81 3.01 1.67 3.34.82 6.74-2.07 8.55-2.93 1.83-6.29 1.17-8.53-2-2.1-2.97-3.98-6.11-5.8-9.26-1.22-2.12-2.59-2.64-4.77-1.4-2.71 1.54-5.47 3.03-8.31 4.31-3.43 1.55-6.81.4-8.37-2.59-1.58-3.02-.63-6.44 2.59-8.43 2.65-1.64 5.45-3.05 8.24-4.46 2.04-1.02 2.49-2.27 1.44-4.38-5.79-11.68-10.8-23.67-14.52-36.19-.53-1.8-1.7-2.54-3.48-2.43-1.82.11-3.63.38-5.44.42-3.83.07-6.79-2.48-7.01-5.92-.21-3.29 2.23-6.07 5.85-6.67 7.77-1.3 7.62-1.32 8.62-9.38 1.27-10.26 4.98-19.66 11.07-28.02 1.64-2.25 1.72-3.81-.46-5.6-1.2-.98-2.3-2.15-3.17-3.43-1.95-2.82-1.84-5.7.64-8.14 2.35-2.31 5.82-2.92 7.72-.54 5.02 6.27 9.06 3.62 14.2.14 8.09-5.47 17.39-8.24 27.13-9.03 3.21-.26 4.88-1.16 4.67-4.58-.06-1.01.22-2.07.49-3.07.88-3.14 3.03-4.97 6.25-4.82 3.42.16 6.23 2.45 5.8 5.66-.95 7.06 3.09 8.5 8.57 10.09 9.26 2.69 17.31 7.86 23.99 14.84 2.66 2.78 4.52 2.75 6.93-.01 1.36-1.56 2.99-2.91 4.64-4.17 2.91-2.23 6.17-2.02 8.46.36 2.2 2.28 2.37 5.89.09 8.52-1.78 2.06-3.84 3.89-5.89 5.69-1.65 1.45-1.75 2.67-.08 4.17 2.91 2.6 5.78 5.26 8.59 7.96 3.27 3.15 3.77 6.93 1.37 9.66-2.65 3.02-6.14 2.9-9.95-.43-7.26-6.35-14-13.23-20.57-20.28-14.62-15.68-32.62-20.93-52.92-14.68-20.42 6.29-31.83 21.19-35.24 42.19-.31 1.9-.04 3.9-.04 5.85Z"
      className="cls-1"
    />
    <path
      d="M222.25 200.82c-.04 15.11-11.98 26.88-27.15 26.78-14.74-.1-26.66-12.22-26.61-27.06.05-15.05 12.01-26.74 27.27-26.66 15.02.08 26.52 11.77 26.48 26.93Zm-26.81 13.96c8 0 14.27-6.06 14.39-13.91.12-7.77-6.61-14.62-14.34-14.6-8.02.02-14.22 6.38-14.17 14.55.05 8.12 5.97 13.98 14.12 13.97Z"
      className="cls-1"
    />
  </svg>
    </>
  )
}
