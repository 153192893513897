import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <section className="position-relative pt-md-4">
        <div className="container">
          <div className="row   "></div>
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-404">
                <h1>404</h1>
              </div>
              <h2>WE ARE SORRY, PAGE NOT FOUND!</h2>
              <p>
                THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS
                NAME CHANGED OR IS TEMPORARILY UNAVAILABLE.
              </p>
              <div className="col-12  mx-auto text-center  flex-center ">
                <Link
                  to="/"
                  className="button button--aylen button--round-l footer_btn button--text-thick mx-auto text-uppercase gradient justify-content-start"
                >
                  Back to Homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
