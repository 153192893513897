import React from 'react'

export const HighOn = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 140 140"
    {...props}
  >
    <defs>
      <style>{".cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="M71.14 5.25c9.86 3.1 19.72 6.21 29.59 9.31 7.41 2.33 14.82 4.64 22.23 6.97 2.01.63 2.5 1.32 2.5 3.4 0 15.04.18 30.07-.05 45.11-.26 17.09-6.38 31.98-17.94 44.54-9.79 10.64-21.87 17.35-36.02 20.34-.89.19-1.74.19-2.63 0-13.4-2.8-24.96-9.04-34.56-18.76-10.24-10.38-16.45-22.87-18.72-37.29-.53-3.39-.76-6.79-.75-10.21.02-14.49 0-28.97 0-43.46 0-2.46.46-3.01 2.86-3.76 17-5.32 34-10.65 50.99-15.98.16-.05.31-.12.47-.19h2.03ZM20.04 48.4c0 6.55-.06 13.09.02 19.64.06 4.98.4 9.96 1.69 14.8 6.5 24.29 21.9 39.76 46.15 46.4 1.05.29 2.12.53 3.24.28 11.49-2.56 21.54-7.86 29.96-16.09 12.58-12.3 18.88-27.38 19.08-44.92.16-13.68.02-27.37.05-41.05 0-.94-.36-1.33-1.21-1.59-15.92-4.96-31.83-9.94-47.73-14.94-.81-.25-1.54-.24-2.34.02-15.82 4.98-31.64 9.95-47.48 14.87-1.17.36-1.46.9-1.45 2.05.05 6.84.03 13.68.03 20.52Z"
      className="cls-1"
    />
    <path
      d="M111.26 53.55c0 5.66.09 11.32-.02 16.98-.16 7.98-2.26 15.5-5.86 22.6-.75 1.47-1.73 2.05-2.98 1.88-1.9-.25-2.84-2.16-1.9-4.06 1.49-2.99 2.74-6.07 3.67-9.29 1.26-4.37 1.82-8.85 1.84-13.38.06-10.01 0-20.02.05-30.03 0-1.19-.37-1.7-1.5-2.04-11.13-3.44-22.24-6.93-33.36-10.42-.72-.23-1.38-.23-2.1 0-11.15 3.5-22.31 7-33.48 10.46-1.09.34-1.41.87-1.41 1.98.04 10.01-.21 20.03.07 30.03.6 20.99 10.45 36.02 29.61 44.82.23.11.46.21.7.29 6.7 2.26 4.44 2.33 11.19-.1 3.55-1.28 6.8-3.19 9.88-5.37.73-.52 1.46-1.07 2.41-.96 1.14.12 2.02.68 2.34 1.83.36 1.27-.04 2.32-1.12 3.11a53.962 53.962 0 0 1-9.97 5.74c-2.16.96-4.42 1.68-6.66 2.43-1.76.59-3.43.55-5.19-.03-21.06-6.97-33.7-21.33-37.9-43.09-.47-2.44-.62-4.93-.61-7.43.02-11.36 0-22.72 0-34.08 0-2.14.44-2.77 2.47-3.4 12.36-3.88 24.73-7.75 37.09-11.64 1.05-.33 2.02-.36 3.08-.03 12.4 3.91 24.81 7.78 37.21 11.68 2.01.63 2.43 1.27 2.43 3.43v18.12Z"
      className="cls-1"
    />
    <path
      d="M45.95 69.69c-.04-.84.33-1.49.89-2.05 2.74-2.75 5.47-5.51 8.24-8.23 1.37-1.35 2.8-1.33 4.23.05 1.79 1.73 3.56 3.48 5.28 5.29.74.78 1.21.7 1.93-.03 4.66-4.71 9.35-9.39 14.04-14.07 1.91-1.91 3.14-1.91 5.05 0 2.51 2.5 5.02 5.01 7.52 7.52 1.65 1.66 1.65 3.02-.02 4.69C84.7 71.28 76.29 79.7 67.86 88.1c-1.65 1.65-3.03 1.66-4.69 0-5.38-5.36-10.75-10.74-16.12-16.11-.64-.64-1.17-1.34-1.09-2.3ZM87.7 60.4c-.16-.2-.35-.47-.58-.7-1.01-1.02-2.08-1.99-3.02-3.06-.73-.83-1.2-.83-2-.02-4.71 4.78-9.47 9.51-14.22 14.25-1.72 1.71-3.04 1.7-4.77-.01-1.77-1.75-3.53-3.51-5.28-5.28-.46-.47-.8-.64-1.34-.07-1.13 1.2-2.3 2.35-3.49 3.49-.51.49-.52.86-.01 1.36 3.95 3.93 7.89 7.86 11.81 11.81.54.54.92.55 1.46 0 6.97-6.99 13.95-13.97 20.93-20.95.2-.2.45-.39.5-.82ZM96.34 104.43c-1.53.02-2.7-1.1-2.71-2.57 0-1.5 1.15-2.66 2.64-2.66 1.49 0 2.65 1.17 2.63 2.66-.01 1.46-1.1 2.55-2.57 2.57Z"
      className="cls-1"
    />
  </svg>
    </>
  )
}
