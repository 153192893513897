import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    id="google-play"
    {...props}
  >
    <path d="m16.444 17.1c-.201 0-.4-.081-.546-.236l-4.1-4.35c-.272-.289-.272-.74 0-1.028l4.1-4.35c.236-.25.611-.308.912-.141l4.846 2.709c.829.466 1.344 1.345 1.344 2.296s-.515 1.83-1.343 2.295l-4.847 2.71c-.115.064-.241.095-.366.095zm-3.069-5.1 3.214 3.41 4.335-2.424c.355-.199.576-.577.576-.986s-.221-.787-.577-.986l-4.334-2.424z" />
    <path d="m2.281 23.428c-.199 0-.391-.08-.531-.221-.483-.485-.75-1.136-.75-1.832v-18.75c0-.696.267-1.347.75-1.833.141-.141.332-.221.531-.221h.011c.202.004.396.089.535.237l10.063 10.678c.272.289.272.74 0 1.028l-10.063 10.678c-.14.148-.333.233-.535.236-.004 0-.008 0-.011 0zm.219-20.781v18.705l8.814-9.352z" />
    <path d="m3.613 24c-.711 0-1.396-.294-1.878-.808-.272-.289-.272-.74 0-1.028l10.063-10.678c.283-.301.809-.301 1.092 0l4.1 4.35c.158.168.23.4.195.628s-.174.428-.375.541l-11.926 6.665c-.391.216-.829.33-1.271.33zm-.127-1.507c.228.022.462-.02.668-.133l11.085-6.195-2.895-3.071z" />
    <path d="m12.345 12.75c-.207 0-.404-.085-.546-.236l-10.064-10.678c-.272-.289-.272-.739 0-1.028.782-.832 2.135-1.04 3.146-.479l11.93 6.667c.201.113.34.312.375.541s-.037.46-.195.628l-4.1 4.35c-.142.15-.339.235-.546.235zm-8.859-11.243 8.858 9.399 2.895-3.071-11.088-6.196c-.202-.113-.436-.156-.665-.132z" />
  </svg>
);
export default SVGComponent;
