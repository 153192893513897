import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dots } from "../components/svg-components/Dots";
import { Line } from "../components/svg-components/Line";
import { AdvancedTechnology } from "../components/svg-components/AdvancedTechnology";
import { Doctor } from "../components/svg-components/Doctor";
import { InternationalStandard } from "../components/svg-components/InternationalStandard";
import { AboutGradient } from "../components/AboutGradient";
import WhyUs from "../components/WhyUs";
import OurFounders from "../components/OurFounders";
import { Certifactions } from "../components/Certifactions";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AboutData from "../Data/AboutUs.json";
import bannerImageMobile from "../images/about-banner-M.webp";
import bannerImage from "../images/About-us-banner-b.webp";

export const Page = () => {
  const [pageData, setPageData] = useState(null);

  // Use useEffect to set the state after the component has mounted
  useEffect(() => {
    setPageData(AboutData);
  }, []);

  console.log(pageData);

  // useEffect(() => {
  //   axios
  //     .get("/path-to-your-json-file/AboutUs.json") // Adjust the path to your JSON file
  //     .then((response) => {
  //       setPageData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the data:", error);
  //     });
  // }, []);

  // Handle the case where pageData might be null or undefined
  if (!pageData || !pageData.sections) {
    return <div>Loading...</div>;
  }

  const { meta, bannerImages, founders, sections } = pageData;

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="abstract" content={meta.abstract} />
        <meta name="keywords" content={meta.keywords} />
        <link rel="canonical" href={meta.canonical} />
        <meta name="geo.placename" content={meta.geo.placename} />
        <meta name="geo.region" content={meta.geo.region} />
        <meta httpEquiv="content-language" content={meta.language} />

        {/* Open Graph tags */}
        <meta property="og:site_name" content={meta.og.site_name} />
        <meta property="og:type" content={meta.og.type} />
        <meta property="og:url" content={meta.og.url} />
        <meta property="og:title" content={meta.og.title} />
        <meta property="og:description" content={meta.og.description} />
        <meta property="og:street_address" content={meta.og.street_address} />
        <meta property="og:region" content={meta.og.region} />
        <meta property="og:postal_code" content={meta.og.postal_code} />
        <meta property="og:country_name" content={meta.og.country_name} />
        <meta property="og:email" content={meta.og.email} />
        <meta property="og:locale" content={meta.og.locale} />
        <meta
          property="og:locale:alternate"
          content={meta.og.locale_alternate}
        />

        {/* Twitter tags */}
        <meta name="twitter:description" content={meta.twitter.description} />
        <meta name="twitter:site" content={meta.twitter.site} />
        <meta name="twitter:title" content={meta.twitter.title} />

        {/* Other meta tags */}
        <meta
          name="google-site-verification"
          content={meta.google_site_verification}
        />
      </Helmet>

      <main className="d-flex flex-wrap float-start col-12">
        <section className="position-relative __about__">
          <div className="container">
            <div className="row">
              <div
                className="col-12 abt_banner"
                data-aos="fade-in"
                data-aos-duration={600}
                data-aos-once="true"
                data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in"
              >
                <img
                  src={bannerImage}
                  alt="Comprehensive Health Checkup"
                  width={1220}
                  height={730}
                  className="desktop-show d-none d-sm-block"
                />
                <img
                  src={bannerImageMobile}
                  className="mobile-show d-block d-sm-none"
                  alt="Comprehensive Health Checkup"
                />
              </div>

              <div role="presentation" className="bread_crums">
                <div role="presentation" className="bread_crums ">
                  <Breadcrumbs className="mb-3 " aria-label="breadcrumb">
                    <Link href="/">Home</Link>
                    <Link href="#">About Us</Link>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div
                  className="title col-12 float-start text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h1 className="text-uppercase">{sections.aboutUs.title}</h1>
                </div>
                <div className="m-auto col-12 col-md-10 d-flex justify-content-center text-center">
                  <div className="d-flex justify-content-center flex-column">
                    {sections.aboutUs.content.map((paragraph, index) => (
                      <p
                        key={index}
                        className="about-assure-text"
                        data-aos="fade-up"
                        data-aos-duration={500}
                        data-aos-once="true"
                        data-aos-anchor-placement="top-bottom"
                      >
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10" />
          <Line className="svgwidthline position-absolute opacity-10" />
          <div className="bg-gradient-image about_page position-absolute col-12">
            <AboutGradient />
          </div>
        </section>

        <OurFounders Data={sections.founders} />

        <section className="position-relative mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto col-12 float-start sm-padding-0">
                <div className="col-12 flaot-start flex-center mb-2 mb-md-4 mb-lg-5 text-center">
                  <div
                    className="title col-12 float-start text-center"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-once="true"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="ease-in"
                  >
                    <h2>{sections.ethos.title}</h2>
                  </div>
                </div>
                <div className="overview col-lg-12 float-start">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-12">
                      <div className="heading p-0 h-auto border-0 mb-2">
                        <h3
                          className="effectheading newheading"
                          data-aos="flip-right"
                          data-aos-duration={800}
                          data-aos-once="true"
                          data-aos-anchor-placement="top-bottom"
                          data-aos-easing="ease-in"
                        >
                          {sections.ethos.vision.title}
                        </h3>
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-12"
                      data-aos="fade-up"
                      data-aos-duration={600}
                      data-aos-once="true"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="ease-in"
                    >
                      <p>{sections.ethos.vision.content}</p>
                    </div>
                  </div>
                </div>
                <div className="overview col-lg-12 float-end mt-5">
                  <div className="row align-items-center flex-row-reverse">
                    <div
                      className="col-lg-6 col-12"
                      style={{ textAlign: "right" }}
                    >
                      <div className="heading p-0 h-auto border-0 mb-2">
                        <h3
                          className="effectheading newheading"
                          data-aos="flip-left"
                          data-aos-duration={800}
                          data-aos-once="true"
                          data-aos-anchor-placement="top-bottom"
                          data-aos-easing="ease-in"
                        >
                          {sections.ethos.mission.title}
                        </h3>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-12"
                      data-aos="fade-up"
                      data-aos-duration={600}
                      data-aos-once="true"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="ease-in"
                    >
                      <p>{sections.ethos.mission.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative about-whyus">
          <WhyUs Title={sections.whyUs.title} />
        </section>

        <section className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div
                  className="title col-12 float-start text-center mt-5 mb-4"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h2>{sections.differentiators.title}</h2>
                </div>
                <div className="our_diffrenc">
                  <div className="col-lg-8 mx-auto col-12">
                    <div
                      className="row justify-content-center our_diff_cnt"
                      data-aos="fade-up"
                      data-aos-duration={400}
                      data-aos-once="true"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="ease-in"
                    >
                      {sections.differentiators.items.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-3 col-sm-4 col-6 our_diff_item"
                        >
                          <div className="circleicons float-start text-center grid-center">
                            <span className="flex-center mb-2 button button--aylen button--round-l button--text-thick">
                              {item.icon === "AdvancedTechnology" && (
                                <AdvancedTechnology />
                              )}
                              {item.icon === "InternationalStandard" && (
                                <InternationalStandard />
                              )}
                              {item.icon === "Doctor" && <Doctor />}
                            </span>
                            <h3
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            ></h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Certifactions />
      </main>
    </>
  );
};

export default Page;
