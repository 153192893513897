import * as React from "react";
const Refrigreation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 128 128"
    width={256}
    height={256}
    {...props}
  >
    <path
      d="M18 31a7 7 0 107-7A7.00818 7.00818 0 0018 31zm10 0a3 3 0 11-3-3A3.00328 3.00328 0 0128 31zM25 56a7 7 0 10-7-7A7.00818 7.00818 0 0025 56zm0-10a3 3 0 11-3 3A3.00328 3.00328 0 0125 46zM43 24a7 7 0 107 7A7.00818 7.00818 0 0043 24zm0 10a3 3 0 113-3A3.00328 3.00328 0 0143 34zM50 49a7 7 0 10-7 7A7.00818 7.00818 0 0050 49zm-7 3a3 3 0 113-3A3.00328 3.00328 0 0143 52zM39.0033 82c.13538-.11237.25854-.23645.39807-.34473A7.98267 7.98267 0 0136.7536 78H33.24677a7.97752 7.97752 0 01-2.64764 3.65625c.13916.108.2619.23169.39691.34375z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M55.5 57a8.96368 8.96368 0 012.5.3551V22a6.00656 6.00656 0 00-6-6H16a6.00656 6.00656 0 00-6 6V58.21948a8.9163 8.9163 0 014-1.1955V22a2.00229 2.00229 0 012-2H52a2.00229 2.00229 0 012 2V57zM36 67a6.95046 6.95046 0 00.79364 3.225 8.03524 8.03524 0 013.33392-4.04285A2.98712 2.98712 0 0142.733 64.027a8.97143 8.97143 0 011.90448-3.82562A6.97363 6.97363 0 0036 67zM31.92383 67.976a6.94041 6.94041 0 00-6.71936-7.96558 8.97024 8.97024 0 012.255 5.18939A8.02115 8.02115 0 0131.92383 67.976zM5 111H28a5.00589 5.00589 0 005-5V94.65527A12.48521 12.48521 0 0025.415 83.207a.35227.35227 0 01-.07373-.18164V80.35352l2.564-1.68652a3.98921 3.98921 0 001.80078-3.34082V73a4.00427 4.00427 0 00-4-4H23.5V66a5.00589 5.00589 0 00-5-5h-4a5.00589 5.00589 0 00-5 5v3H7.29395a4.00427 4.00427 0 00-4 4v2.32617A3.99074 3.99074 0 005.0957 78.668l2.563 1.68555v2.67188c0 .0498-.042.1377 0 .1377A12.54389 12.54389 0 000 94.65527V106A5.00589 5.00589 0 005 111zm8.5-45a1.001 1.001 0 011-1h4a1.001 1.001 0 011 1v3h-6zM7.29395 75.32617V73H25.70605v2.32617l-2.563 1.68555a3.9882 3.9882 0 00-1.80176 3.3418V83H11.65869V80.35352a3.9882 3.9882 0 00-1.80176-3.3418zM4 94.65527a8.41063 8.41063 0 014.9361-7.66174C8.95782 86.9942 8.97809 87 9 87H24c.02228 0 .04291-.0058.065-.00653A8.41243 8.41243 0 0129 94.65527V106a1.001 1.001 0 01-1 1H5a1.001 1.001 0 01-1-1z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M9 99H24a2 2 0 000-4H9a2 2 0 000 4zM42 111H65a5.00589 5.00589 0 005-5V94.65527A12.48521 12.48521 0 0062.415 83.207a.35227.35227 0 01-.07373-.18164V80.35352l2.564-1.68652a3.98921 3.98921 0 001.80078-3.34082V73a4.00427 4.00427 0 00-4-4H60.5V66a5.00589 5.00589 0 00-5-5h-4a5.00589 5.00589 0 00-5 5v3H44.29395a4.00427 4.00427 0 00-4 4v2.32617A3.99074 3.99074 0 0042.0957 78.668l2.563 1.68555v2.67188c.00049.0498-.03516.1377 0 .1377A12.54389 12.54389 0 0037 94.65527V106A5.00589 5.00589 0 0042 111zm8.5-45a1.001 1.001 0 011-1h4a1.001 1.001 0 011 1v3h-6zm-6.20605 9.32617V73H62.70605v2.32617l-2.563 1.68555a3.9882 3.9882 0 00-1.80176 3.3418V83H48.65869V80.35352a3.9882 3.9882 0 00-1.80176-3.3418zM41 94.65527a8.41063 8.41063 0 014.9361-7.66174c.02173.00067.042.00647.0639.00647H61c.02228 0 .04291-.0058.065-.00653A8.41243 8.41243 0 0166 94.65527V106a1.001 1.001 0 01-1 1H42a1.001 1.001 0 01-1-1z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M46 99H61a2 2 0 000-4H46a2 2 0 000 4zM115 94V73.041a20.2946 20.2946 0 00-5.32324-13.76562A16.28743 16.28743 0 01105.4173 48.455 1.99991 1.99991 0 00107 46.5v-18a6.00656 6.00656 0 00-6-6H89a6.00656 6.00656 0 00-6 6v18a1.99991 1.99991 0 001.5827 1.955 16.28743 16.28743 0 01-4.25946 10.82043A20.2946 20.2946 0 0075 73.041V105a6.94155 6.94155 0 006.86475 7H98.52142a10.981 10.981 0 01-2.095-4H81.86475A2.938 2.938 0 0179 105V98H98.52142A10.98213 10.98213 0 01107 94H79V78h32V94zM87 28.5a2.00229 2.00229 0 012-2h12a2.00229 2.00229 0 012 2v16H87zM79 74v-.959a16.301 16.301 0 014.27051-11.06055A20.27984 20.27984 0 0088.58057 48.5h12.83887a20.27984 20.27984 0 005.31006 13.48047A16.301 16.301 0 01111 73.041V74z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M121,98H107a7,7,0,0,0,0,14h14a7,7,0,0,0,0-14Zm-9,10h-5a3,3,0,0,1,0-6h5Zm9,0h-5v-6h5a3,3,0,0,1,0,6Z"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
);
export default Refrigreation;
