import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 133 133"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className="hovered-paths"
    {...props}
  >
    <g>
      <defs>
        <clipPath id="b">
          <use xlinkHref="#a" />
        </clipPath>
        <path
          id="a"
          d="M102.661 130.5H30.339C14.964 130.5 2.5 118.036 2.5 102.661V30.339C2.5 14.964 14.964 2.5 30.339 2.5h72.322c15.375 0 27.839 12.464 27.839 27.839v72.322c0 15.375-12.464 27.839-27.839 27.839z"
          fill="#000000"
          opacity={1}
          data-original="#000000"
        />
      </defs>
      <use />
      <path
        fill="#00b771"
        d="M102.661 130.5H30.339C14.964 130.5 2.5 118.036 2.5 102.661V30.339C2.5 14.964 14.964 2.5 30.339 2.5h72.322c15.375 0 27.839 12.464 27.839 27.839v72.322c0 15.375-12.464 27.839-27.839 27.839z"
        clipPath="url(#b)"
        opacity={1}
        data-original="#00b771"
        className="hovered-path"
      />
      <path
        fill="#ffffff"
        d="M100.638 84.078V98.59c0 .986-.802 1.783-1.797 1.783h-7.187c-15.835 0-30.724-6.121-41.924-17.234S32.362 57.253 32.362 41.54v-7.13c0-.987.802-1.783 1.797-1.783h14.398c1.869 0 3.486 1.189 4.013 2.936l3.737 15.463a1.796 1.796 0 0 1-.491 1.7l-4.684 4.54a2.934 2.934 0 0 0-.635 3.352 46.966 46.966 0 0 0 3.498 6.121 46.353 46.353 0 0 0 5.546 6.668 46.536 46.536 0 0 0 6.72 5.503 45.524 45.524 0 0 0 6.181 3.471 2.975 2.975 0 0 0 3.366-.63l5.366-5.432a1.796 1.796 0 0 1 1.797-.464l14.925 4.314a4.19 4.19 0 0 1 2.742 3.909z"
        clipPath="url(#b)"
        opacity={1}
        data-original="#ffffff"
      />
    </g>
  </svg>
);
export default SVGComponent;
