import React, { useEffect, useState } from "react";
import { Dots } from "./svg-components/Dots";
import { Line } from "./svg-components/Line";
import { Link } from "react-router-dom";

const OurFounders = ({ Data }) => {
  const [founders, setFounders] = useState([]);

  useEffect(() => {
    // Fetch the founders data from the JSON file
    setFounders(Data);
  }, []);

  return (
    <>
      <section className="position-relative pt-md-4">
        <div className="container">
          <div className="web-container">
            <div className="row">
              <div className="col-12 flex-center  our-founder">
                <div
                  className="our-founder-title flex-center"
                  data-aos="fade-in"
                  data-aos-duration={300}
                  data-aos-once="true"
                  data-aos-easing="ease-in"
                >
                  <h2>
                    Meet The <br /> Founders
                  </h2>
                </div>
                <div className="our-founder-content flex-center">
                  {founders.map((founder, index) => (
                    <div
                      key={index}
                      className="founder-card text-left mb-3 rounded-0"
                      data-aos={index % 2 === 0 ? "fade-left" : "fade-right"}
                      data-aos-duration={500}
                      data-aos-once="true"
                      data-aos-easing="ease-in"
                    >
                      <div className="founder-card-body h-100 justify-content-between d-flex align-items-top">
                        <div>
                          <div className="founder-image mb-5">
                            <img
                              src={founder.image}
                              alt={founder.name}
                              width={500}
                              height={500}
                            />
                          </div>
                          <div className="ml-3 ">
                            <h3 className="fw-bolder">{founder.name}</h3>
                            <p className="text-muted">
                              {founder.description} <br />
                              {founder.role}
                            </p>
                            <p className="founder-details">{founder.details}</p>
                          </div>
                        </div>
                        <div className="packageprice_btn ">
                          <div className="textbtn">
                            <Link to={founder.link}>KNOW MORE +</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dots className="hsection position-absolute svgwidth opacity-10" />
        <Line className="svgwidthline position-absolute opacity-10" />
      </section>
    </>
  );
};

export default OurFounders;
