import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M38.872 149.21h79.098c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H38.872c-4.142 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5zM115.258 185.317H84.012c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h31.246c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zM7.5 237.327h76.512c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H7.5c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5zM125.47 370.289a7.5 7.5 0 0 0-7.5-7.5H38.872c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h79.098a7.5 7.5 0 0 0 7.5-7.5zM115.258 311.683H84.012c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h31.246c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zM91.512 282.173a7.5 7.5 0 0 0-7.5-7.5H7.5c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h76.512a7.5 7.5 0 0 0 7.5-7.5zM319.529 165.83c4.143 0 7.5-3.357 7.5-7.5v-11.448c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v11.448c0 4.143 3.357 7.5 7.5 7.5zM401.99 173.538a7.502 7.502 0 0 0-10.607 0l-8.095 8.095a7.5 7.5 0 0 0 10.607 10.607l8.095-8.095a7.502 7.502 0 0 0 0-10.607zM417.199 248.5c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h11.447c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5zM393.895 319.759a7.5 7.5 0 0 0-10.607 10.607l8.095 8.095a7.501 7.501 0 0 0 10.607-10.607zM312.029 353.67v11.447c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5V353.67c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5zM245.162 319.759l-8.095 8.095a7.5 7.5 0 0 0 10.606 10.607l8.095-8.095a7.5 7.5 0 0 0-10.606-10.607zM210.411 248.5c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h11.448c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zM255.769 181.633l-8.095-8.095a7.501 7.501 0 0 0-10.606 10.607l8.095 8.095a7.5 7.5 0 0 0 10.606-10.607zM359.859 256c0-4.143-3.357-7.5-7.5-7.5h-25.33v-55.684c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5V256c0 4.143 3.357 7.5 7.5 7.5h32.83a7.5 7.5 0 0 0 7.5-7.5z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
      <path
        d="M461.741 254.762a7.5 7.5 0 0 0 7.032-7.94c-4.771-78.703-70.327-140.354-149.245-140.354-82.452 0-149.532 67.08-149.532 149.533 0 82.452 67.08 149.532 149.532 149.532 73.869 0 136.9-54.122 147.865-127.157a7.5 7.5 0 1 0-14.834-2.226c-9.85 65.614-66.506 114.384-133.031 114.384-74.181 0-134.532-60.351-134.532-134.532s60.351-134.533 134.532-134.533c71.001 0 129.98 55.461 134.272 126.263.25 4.132 3.802 7.27 7.941 7.03z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
      <path
        d="M319.528 63.529c-26.381 0-52.228 5.473-76.018 15.708H111.181c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h104.113a192.265 192.265 0 0 0-38.462 32.604 7.5 7.5 0 0 0 11.118 10.069c33.602-37.102 81.561-58.381 131.578-58.381C417.386 78.529 497 158.142 497 256c0 97.857-79.613 177.471-177.472 177.471-97.858 0-177.471-79.613-177.471-177.471 0-34.268 9.789-67.528 28.307-96.186a7.5 7.5 0 0 0-12.599-8.14c-20.09 31.088-30.708 67.163-30.708 104.326 0 67.766 35.206 127.458 88.285 161.763h-104.16c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h132.225c23.36 10.099 49.097 15.708 76.122 15.708C425.657 448.471 512 362.129 512 256S425.657 63.529 319.528 63.529z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
