import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import axios from "axios";
import { BASE_URL } from "../context/UseApi";

export const Faq = ({ slug, initialCount = 5 }) => {
  const [showAll, setShowAll] = useState(false);
  const [count, setCount] = useState(initialCount);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${BASE_URL}/faqs.php?type=${slug}`)
      .then((response) => {
        // console.log("API Response:", response.data);

        if (Array.isArray(response.data)) {
          setData(response.data);
          setFilteredData(
            showAll ? response.data : response.data.slice(0, count)
          );
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          // If the response is an object, it might contain the array we need
          const dataArray = Object.values(response.data).find(Array.isArray);
          if (dataArray) {
            setData(dataArray);
            // setFilteredData(showAll ? dataArray : dataArray.slice(0, count));
          } else {
            throw new Error("");
          }
        } else {
          throw new Error("");
          console.log(Error);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the FAQ data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [showAll, count, slug]);

  // console.log(filteredData);

  const handleReadMore = () => {
    setShowAll(!showAll);
  };

  const shouldShowButton = data.length > count;

  if (loading) {
    return (
      <div className="_loader_cnt col-12 d-flex justify-content-center">
        <div className="_loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="h-screen col-12 flex-center ">{error}</div>;
  }
  const getValidFaqItems = (items) => {
    return items.filter((item) => item.question && item.answer);
  };
  const validFaqItems = getValidFaqItems(data);
  if (validFaqItems.length === 0) {
    return null; // Return null to hide the section when there's no valid data
  }

  return (
    <>
      <div className="title col-12 float-start text-center">
        <h2 className="">Frequently Asked Questions</h2>
      </div>
      <div className="col-lg-11 col-md-11 col-12 m-auto float-start">
        <div
          className="faqcont col-lg-11 col-12"
          data-aos="fade-up"
          data-aos-delay={100}
          data-aos-duration={300}
          data-aos-once="true"
          data-aos-easing="ease"
        >
          <Accordion allowZeroExpanded={true}>
            {(showAll
              ? data.filter((item) => item.question && item.answer)
              : data
                  .filter((item) => item.question && item.answer)
                  .slice(0, count)
            ).map((item, index) => (
              <AccordionItem key={index}>
                <h3>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.question}</AccordionItemButton>
                  </AccordionItemHeading>
                </h3>
                <AccordionItemPanel>
                  <div
                    className="accordiancont"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  ></div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {shouldShowButton && (
            <div className="col-12 pt-3 mx-auto text-center d-flex justify-content-center">
              <button
                className="button button--aylen button--round-l button--text-thick mx-auto gradient col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-11 "
                onClick={handleReadMore}
              >
                {showAll ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
