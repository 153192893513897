import { Faq } from "./components/Faq";
import TestCategory from "./components/TestCategory";
import { Dots } from "./components/svg-components/Dots";
import { Line } from "./components/svg-components/Line";
import faqData from "./Data/Faqs_subcat.json";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Page = () => {
  const { slug } = useParams();

  const categoryData = faqData[slug] || [];
  const formattedTitle = slug
    ?.split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  // Create a general description based on the category
  const description = `Browse our comprehensive list of ${formattedTitle} tests and packages. Get detailed information about available medical tests, prices, and book home sample collection services.`;

  // Generate relevant keywords for the category
  const keywords = [
    formattedTitle,
    "medical tests",
    "diagnostic tests",
    "health checkup",
    "laboratory tests",
    "home collection",
    "medical diagnostics",
    "health screening",
    "pathology tests",
    "lab tests india",
  ]
    .filter(Boolean)
    .join(", ");
  return (
    <>
      <Helmet>
        <title>{`${formattedTitle} Tests & Packages | Medical Laboratory Services`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`${formattedTitle} Tests & Packages`}
        />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`${formattedTitle} Tests & Packages`}
        />
        <meta name="twitter:description" content={description} />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Schema.org Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: `${formattedTitle} Tests & Packages`,
            description: description,
            offers: {
              "@type": "AggregateOffer",
              priceCurrency: "INR",
            },
            provider: {
              "@type": "MedicalOrganization",
              name: "Medical Laboratory Services",
              serviceType: "Medical Tests and Diagnostics",
            },
          })}
        </script>
      </Helmet>
      <section className="position-relative">
        <div className="container">
          <div className="web-container">
            <TestCategory Slug={slug} />
            <Dots className="hsection position-absolute svgwidth opacity-10 end-0 left-inherit" />
            <Line className="svgwidthline position-absolute opacity-10 top-20 start-0" />
          </div>
        </div>
      </section>
      <section id="faq" className="faq pt-5 col-12 ">
        <div className="container">
          <div className="web-container">
            <div className="row">
              <Faq className="minusbottom" slug={slug} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page;
