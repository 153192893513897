import React, { useEffect, useState } from "react";
import "./index.css";
import "aos/dist/aos.css"; // Import AOS CSS
import axios from "axios";
import { BASE_URL } from "./context/UseApi";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const DoctorDetails = () => {
  const { slug } = useParams();
  const [doctorData, setDoctorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fullName = doctorData.doctor_name || "";
  const firstName = fullName.split(" ")[0];
  const lastName = fullName.split(" ").slice(1).join(" ");
  const pageUrl = window.location.href;

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${BASE_URL}/doctor_data.php`)
      .then((response) => {
        // Assuming the API returns data in the format { doctors: [...] }
        const doctorData = response.data.find((p) => p.slug === slug) || null;
        setDoctorData(doctorData);
        console.log("asff", response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the doctor data:", error);
        setError("Failed to load doctor data.");
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <div className="_loader_cnt col-12 d-flex justify-content-center">
        <div className="_loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="h-screen col-12 flex-center ">{error}</div>;
  }

  const renderList = (list, title, className, subtitle) =>
    list &&
    list.length > 0 && (
      <div
        className={`docinfo-row ${className}`}
        data-aos="fade-up"
        data-aos-delay={100}
        data-aos-duration={400}
        data-aos-once="true"
      >
        <div className="heading">
          <h3>
            <strong>{title}</strong>
          </h3>
        </div>
        <div className="abt_cont">
          <p>{subtitle}</p>
          {Array.isArray(list) && (
            <ul>
              {list.map((item, index) => (
                <li>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          )}
          {typeof list === "string" && <h5 className="">{list}</h5>}
        </div>
      </div>
    );

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{`${fullName} - ${doctorData.position} at ${doctorData.organization}`}</title>
        <meta
          name="title"
          content={`${fullName} - ${doctorData.position} at ${doctorData.organization}`}
        />
        <meta
          name="description"
          content={`Learn about ${fullName}, ${doctorData.position} at ${doctorData.organization}. ${doctorData.doctor_education}`}
        />
        <meta
          name="keywords"
          content={`${fullName}, ${doctorData.position}, ${doctorData.organization}, ${doctorData.doctor_education}, medical expert, health specialist`}
        />
        <meta name="author" content={fullName} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={pageUrl} />
        <meta
          property="og:title"
          content={`${fullName} - ${doctorData.position}`}
        />
        <meta
          property="og:description"
          content={`Explore ${fullName}'s profile, ${doctorData.position} at ${doctorData.organization}.`}
        />
        <meta property="og:image" content={doctorData.image.src} />
        <meta property="profile:first_name" content={firstName} />
        <meta property="profile:last_name" content={lastName} />
        <meta property="profile:username" content={doctorData.slug} />
        <meta property="profile:gender" content={doctorData.gender || "male"} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={pageUrl} />
        <meta
          name="twitter:title"
          content={`${fullName} - ${doctorData.position}`}
        />
        <meta
          name="twitter:description"
          content={`Explore ${fullName}'s profile, ${doctorData.position} at ${doctorData.organization}.`}
        />
        <meta name="twitter:image" content={doctorData.image.src} />
        <meta name="twitter:site" content="@assurepathlabs" />
        <meta name="twitter:creator" content={doctorData.socialHandle || ""} />

        {/* Canonical */}
        <link rel="canonical" href={pageUrl} />

        {/* Alternate */}
        <link rel="alternate" hreflang="en" href={pageUrl} />
        <link
          rel="alternate"
          hreflang="es"
          href={pageUrl.replace("/en/", "/es/")}
        />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            name: fullName,
            jobTitle: doctorData.position,
            worksFor: {
              "@type": "Organization",
              name: doctorData.organization,
            },
            image: doctorData.image.src,
            url: pageUrl,
            sameAs: doctorData.socialLinks
              ? [
                  doctorData.socialLinks.linkedin,
                  doctorData.socialLinks.twitter,
                  doctorData.socialLinks.facebook,
                ]
              : [],
          })}
        </script>
      </Helmet>
      <section className="position-relative">
        <div className="container">
          <div className="web-container">
            <div
              className="row doctor_name "
              data-aos="fade-up"
              data-aos-duration={200}
              data-aos-once="true"
            >
              <div className="procont ">
                <div
                  className="doctor_name_img"
                  data-aos="fade-in"
                  data-aos-duration={600}
                  data-aos-once="true"
                >
                  <img
                    src={doctorData?.image?.src}
                    alt="Dr. Sanjay Wadhwa"
                    title="Dr. Sanjay Wadhwa"
                    width="250"
                    height="250"
                  />
                </div>
                <div
                  className="procont_name"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                >
                  <h2>{doctorData?.doctor_name}</h2>
                  <strong>{doctorData?.doctor_education}</strong>
                  <p>{doctorData?.doctor_details}</p>
                </div>
              </div>
            </div>
            <div className="row gap-3 abt_doc_profile">
              {doctorData.qualifications &&
                renderList(
                  doctorData.qualifications,
                  "Qualifications",
                  "qualifi"
                )}
              {doctorData.experience &&
                renderList(
                  doctorData.experience.details,
                  "Experience",
                  "experience",
                  doctorData.experience.title
                )}
              {doctorData.clinical_expertise &&
                renderList(
                  doctorData.clinical_expertise.details,
                  "Clinical Expertise",
                  "clinical",
                  doctorData.clinical_expertise.title
                )}
              {doctorData.advanced_training &&
                renderList(
                  doctorData.advanced_training,
                  "Advanced Training",
                  "advanced"
                )}
              {doctorData.professional_associations &&
                renderList(
                  doctorData.professional_associations,
                  "Professional Associations",
                  "prosses"
                )}
              {doctorData.academic_participations &&
                renderList(
                  doctorData.academic_participations,
                  "Academic Participations",
                  "academic"
                )}
              {doctorData.affiliations &&
                renderList(
                  doctorData.affiliations,
                  "Affiliation with NABL",
                  "association"
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorDetails;
